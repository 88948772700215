const checkoutIncludes = [
  "bill_address",
  "ship_address",
  "user.addresses",
  "user.wallet_payment_sources.payment_source",
  "shipments.shipping_rates.discounts",
  "shipments.manifest_items.kit_items",
  "line_items.variant.images",
  "line_items.variant.product_url",
  "payments.source",
  "available_payment_methods",
  "storefront_closure",
].join(",")

const cartIncludes = [
  "line_items.variant",
  "line_items.kit_items",
  "shipments.shipping_rates.discounts",
  "shipments.manifest_items.kit_items",
].join(",")

export { checkoutIncludes, cartIncludes }
